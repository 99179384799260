<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Sistem Administrasi Umum</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>
                Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan
                Fisika
              </h4>
            </div>
            <hr style="height: 1px; background: black" />

            <table class="table table-borderless">
              <tr style="line-height: 1">
                <td>Nama Mahasiswa</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIM</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Semester ke-</td>
                <td>:</td>
                <td>{{ detail.registration_number }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Tahun ke-</td>
                <td>:</td>
                <td>{{ detail.year_id }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Program Studi</td>
                <td>:</td>
                <td>{{ detail.study_program_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Fakultas</td>
                <td>:</td>
                <td>{{ detail.faculty_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No. Telp/Wa</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Email</td>
                <td>:</td>
                <td>{{ detail.email }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Jenis Surat</td>
                <td>:</td>
                <td>Surat Permohonan Pembelian Bahan Kimia</td>
              </tr>
              <tr style="line-height: 1">
                <td>Dosen Pembimbing</td>
                <td>:</td>
                <td>{{ detail.teacher_mentor_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Judul/Topik Penelitian</td>
                <td>:</td>
                <td>{{ detail.title }}</td>
              </tr>
              <tr>
                <td>Bahan Kimia yang Diperlukan</td>
                <td>:</td>
                <td>
                  <ol class="ml-3">
                    <li v-for="tool in detail.lab_materials_display" :key="tool.id">{{tool.name}}</li>
                  </ol>
                </td>
              </tr>
              <tr style="line-height: 1">
                <td>Tempat Pembelian</td>
                <td>:</td>
                <td>{{ detail.purchase_place }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Peruntukan</td>
                <td>:</td>
                <td>{{ detail.allotment }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Keterangan</td>
                <td>:</td>
                <td>{{ detail.description }}</td>
              </tr>
            </table>

            <!-- <div class="d-flex justify-content-start mt-2">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  image-container
                "
              >
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <b-button
          variant="danger"
          v-b-tooltip.hover
          title="Cetak PDF"
          @click="downloadPDF"
        >
          <i class="fas fa-file-pdf px-0"></i>
        </b-button>
      </div>
    </div>
    <b-button
      @click="$router.push('/letters/chemical-purchase/list')"
      variant="secondary"
      class="mt-3"
      >Tutup</b-button
    >

    <!-- Layout PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="500"
      filename="Surat Permohonan Pembelian Bahan Kimia"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="950px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <DetailPdf :detail="detail"/>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import DetailPdf from "@/view/pages/letters/chemical-purchase/DetailPdf.vue"
import VueHtml2pdf from "vue-html2pdf";

export default {
  components:{
    DetailPdf,
    VueHtml2pdf
  },
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    async get() {
      this.detail = await module.get(
        "api/chemical-purchase-forms/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/letters/chemical-purchase/list");
      }
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    setStartTime(time){
        let date = new Date(time);
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('id-ID', options) + " " + date.toLocaleTimeString('id-ID')
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Permohonan Pembelian Bahan Kimia", route: "/letters/chemical-purchase/list" },
      { title: "Detail" },
    ]);

    this.get();
  },
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>